import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { SegmentedMessage } from 'sms-segments-calculator';
import moment from 'moment';
import emojiData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Select from 'react-select';
import Dropzone, {useDropzone} from 'react-dropzone';
import axios from 'axios';

import ToolTipWrapper from '../../../../Misc/ToolTipWrapper';
import CustomTimePicker from '../../../../Misc/CustomTimePicker';

import { ISD_CODES, MSGTYPE_TO_STR } from '../../../../../helpers';

import emojiIcon from '../../../../../img/icons/emoji.svg';

import './style.css';

const sendAfterTimes = [];
sendAfterTimes.push({ label: '30 Secs', value: '30 Secs' });
sendAfterTimes.push({ label: '2 Mins', value: '2 Mins' });
sendAfterTimes.push({ label: '10 Mins', value: '10 Mins' });
sendAfterTimes.push({ label: '30 Mins', value: '30 Mins' });
sendAfterTimes.push({ label: '60 Mins', value: '60 Mins' });
sendAfterTimes.push({ label: '24 Hrs', value: '24 Hrs' });

const sendBeforeDays = [];
sendBeforeDays.push({ label: 'Same Day', value: 'Same Day' });
sendBeforeDays.push({ label: '1 Day', value: '1 Day' });
sendBeforeDays.push({ label: '2 Days', value: '2 Days' });
sendBeforeDays.push({ label: '3 Days', value: '3 Days' });
sendBeforeDays.push({ label: '4 Days', value: '4 Days' });
sendBeforeDays.push({ label: '5 Days', value: '5 Days' });
sendBeforeDays.push({ label: '10 Days', value: '10 Days' });

const personalizeOptionsG = [];
personalizeOptionsG.push({ label: 'Personalize', value: 'personalize' });
personalizeOptionsG.push({ label: 'First Name', value: '{{firstname}}' });
personalizeOptionsG.push({ label: 'Last Name', value: '{{lastname}}' });
// personalizeOptionsG.push({ label: 'Self CheckIn Link', value: '{{selfcheckinlink}}' });
personalizeOptionsG.push({ label: 'Room Type', value: '{{roomtype}}' });
personalizeOptionsG.push({ label: 'Room Number', value: '{{roomnumber}}' });
// personalizeOptionsG.push({ label: 'Wifi', value: '{{wifi}}' });

const sendEmailOptionsG = [];
const sendEmailOptions_remaining = { label: 'Send email to the remaining cases', value: 'remaining' };
const sendEmailOptions_all = { label: 'Send email to all', value: 'all' };
sendEmailOptionsG.push(sendEmailOptions_remaining);
sendEmailOptionsG.push(sendEmailOptions_all);

function PreDefinedMessage(props) {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [hasMessage, setHasMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [sendAfterValue, setSendAfterValue] = useState([
    { label: '30 Secs', value: '30 Secs' }
  ]);
  const [sendBeforeValue, setSendBeforeValue] = useState([
    { label: '1 Day', value: '1 Day' }
  ]);
  const [sendAt, setSendAt] = useState('');
  const [timeConstraintsScheduleTime, setTimeConstraintsScheduleTime] = useState({ minutes: { step: 15 }});
  const [sendAtError, setSendAtError] = useState(false);

  const [personalizeOptions, setPersonalizeOptions] = useState([]);
  const [personalizeValue, setPersonalizeValue] = useState([
    { label: 'Personalize', value: 'personalize' }
  ]);
  const [emojiSelectorOpen, setEmojiSelectorOpen] = useState(false);

  const [segmentInfo, setSegmentInfo] = useState(null);

  const messageEl = useRef(null);

  const [smsIsdCodesOptions, setSmsIsdCodesOptions] = useState([]);
  const [smsIsdCodesValues, setSmsIsdCodesValues] = useState([]);

  const [whatsappIsdCodesOptions, setWhatsappIsdCodesOptions] = useState([]);
  const [whatsappIsdCodesValues, setWhatsappIsdCodesValues] = useState([]);

  const [sendEmailOptions, setSendEmailOptions] = useState([]);
  const [sendEmailValues, setSendEmailValues] = useState({});

  const [approvedTemplateOptions, setApprovedTemplateOptions] = useState([]);
  const [selectedApprovedTemplate, setSelectedApprovedTemplate] = useState({});
  const [whatsappMessage, setWhatsappMessage] = useState('');


  useEffect(() => {
    if (props.type === 'preArrivalMessage') {
      let newOptions = [];
      newOptions.push({ label: 'Wifi', value: '{{wifi}}' });
      newOptions.push({ label: 'Keycode', value: '{{keycode}}' });
      newOptions.push({ label: 'Self CheckIn Link', value: '{{selfcheckinlink}}' });
      if (props.locationInfo && props.locationInfo.has_stayhq) {
        newOptions.push({ label: 'Guest Engage WebApp Link (stayHQ link)', value: '{{guestengagewebapplink}}' });
      }
      setPersonalizeOptions([
        ...personalizeOptionsG,
        ...newOptions,
      ]);
    }
    if (props.type === 'welcomeMessage') {
      let newOptions = [];
      newOptions.push({ label: 'Keycode', value: '{{keycode}}' });
      newOptions.push({ label: 'Self CheckIn Link', value: '{{selfcheckinlink}}' });
      newOptions.push({ label: 'Wifi', value: '{{wifi}}' });
      // setPersonalizeOptions([
      //   ...personalizeOptionsG,
      //   { label: 'Wifi', value: '{{wifi}}' },
      // ]);
      if (props.locationInfo && props.locationInfo.subdomain && props.locationInfo.mp_is_complete && props.locationInfo.mp_is_active) {
        newOptions.push({ label: 'Info Link', value: '{{infolink}}' });
      }
      if (props.locationInfo && props.locationInfo.has_stayhq) {
        newOptions.push({ label: 'Guest Engage WebApp Link (stayHQ link)', value: '{{guestengagewebapplink}}' });
      }
      setPersonalizeOptions([
        ...personalizeOptionsG,
        ...newOptions,
      ]);
    }
    if (props.type === 'repeatWelcomeMessage') {
      let newOptions = [];
      newOptions.push({ label: 'Keycode', value: '{{keycode}}' });
      newOptions.push({ label: 'Self CheckIn Link', value: '{{selfcheckinlink}}' });
      newOptions.push({ label: 'Wifi', value: '{{wifi}}' });
      if (props.locationInfo && props.locationInfo.subdomain && props.locationInfo.mp_is_complete && props.locationInfo.mp_is_active) {
        newOptions.push({ label: 'Info Link', value: '{{infolink}}' });
      }
      if (props.locationInfo && props.locationInfo.has_stayhq) {
        newOptions.push({ label: 'Guest Engage WebApp Link (stayHQ link)', value: '{{guestengagewebapplink}}' });
      }
      setPersonalizeOptions([
        ...personalizeOptionsG,
        ...newOptions,
      ]);
    }
    if (props.type === 'departureMessage') {
      let newOptions = [];
      newOptions.push({ label: 'Review Link', value: '{{reviewlink}}' });
      if (props.locationInfo && props.locationInfo.subdomain && props.locationInfo.mp_is_complete && props.locationInfo.mp_is_active) {
        newOptions.push({ label: 'Info Link', value: '{{infolink}}' });
      }
      setPersonalizeOptions([
        ...personalizeOptionsG,
        ...newOptions,
      ]);
    }
    let newOptions = [];
    newOptions.push({ label: 'All countries', value: 'all' });
    let homeCountry = null;
    if (props.locationInfo && props.locationInfo.isd_code) {
      homeCountry = ISD_CODES.find(el => el.dial_code === props.locationInfo.isd_code);
      if (process.env.NODE_ENV !== 'production') {
        console.log('homeCountry: ', homeCountry);
      }
      if (homeCountry) {
        newOptions.push({ label: `Home Country ${homeCountry.dial_code}`, value: homeCountry.dial_code, homeCountry: true });
      }
    }
    ISD_CODES.forEach((item) => {
      if (homeCountry && homeCountry.dial_code !== item.dial_code) {
        newOptions.push({ label: `${item.name} ${item.dial_code}`, value: item.dial_code, data: item });
      }
    });
    setSmsIsdCodesOptions([
      ...newOptions,
    ]);

    setSendEmailOptions([...sendEmailOptionsG]);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('props.hasMessage: ', props.hasMessage, ' # ', props.type);
    }
    setHasMessage(props.hasMessage)
  }, [props.hasMessage]);

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);
  useEffect(() => {
    setWhatsappMessage(props.whatsappMessage);
  }, [props.whatsappMessage]);

  useEffect(() => {
    setSendAfterValue({ label: props.sendAfter, value: props.sendAfter });
    // if (props.sendAfter) {
    //   setSendAfterValue({ label: props.sendAfter, value: props.sendAfter });
    // } else {
    //   setSendAfterValue({ label: '30 Secs', value: '30 Secs' });
    // }
  }, [props.sendAfter]);

  useEffect(() => {
    if (props.sendBefore === '0 Day') {
      setSendBeforeValue({ label: 'Same Day', value: 'Same Day' });
    } else {
      setSendBeforeValue({ label: props.sendBefore, value: props.sendBefore });
    }
  }, [props.sendBefore]);
  useEffect(() => {
    setSendAt(props.sendAt ? new Date(moment(props.sendAt)) : '');
  }, [props.sendAt]);

  useEffect(() => {
    setSmsIsdCodesValues(props.smsCodesList);
    updateWhatsappIsdCodeSelectOptions(props.smsCodesList || []); // nkcheck
  }, [props.smsCodesList]);
  useEffect(() => {
    setWhatsappIsdCodesValues(props.whatsappCodesList);
  }, [props.whatsappCodesList]);
  useEffect(() => {
    if (props.sendEmail === 'all') {
      setSendEmailValues(sendEmailOptions_all);
    }
    if (props.sendEmail === 'remaining') {
      setSendEmailValues(sendEmailOptions_remaining);
    }
  }, [props.sendEmail]);

  useEffect(() => {
    if (message) {
      try {
        const segmentedMessage = new SegmentedMessage(message);
        setSegmentInfo(segmentedMessage);
      } catch (e) {
        setSegmentInfo(null);
      }
    } else {
      setSegmentInfo(null);
    }
  }, [message]);

  const removeSmsIsdCodeSelectOption = (value) => {
    let newOptions =  [...smsIsdCodesOptions ];
    newOptions = newOptions.filter(el => el.value !== value.value);
    setSmsIsdCodesOptions([
      ...newOptions,
    ]);
  };

  const updateWhatsappIsdCodeSelectOptions = (smsIsdCodesSelected) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('updateWhatsappIsdCodeSelectOptions smsIsdCodesSelected: ', smsIsdCodesSelected);
    }
    let newOptions = [];
    newOptions.push({ label: 'All remaining countries', value: 'all-remaining' });
    let homeCountry = null;
    if (!smsIsdCodesSelected.find(el => el.homeCountry)) {
      if (props.locationInfo && props.locationInfo.isd_code) {
        homeCountry = ISD_CODES.find(el => el.dial_code === props.locationInfo.isd_code);
        if (process.env.NODE_ENV !== 'production') {
          console.log('homeCountry: ', homeCountry);
        }
        if (homeCountry) {
          newOptions.push({ label: `Home Country ${homeCountry.dial_code}`, value: homeCountry.dial_code, homeCountry: true });
        }
      }
    }
    ISD_CODES.forEach((item) => {
      // if (homeCountry && homeCountry.dial_code !== item.dial_code) {
        if (!smsIsdCodesSelected.find(el => el.value === item.dial_code)) {
          newOptions.push({ label: `${item.name} ${item.dial_code}`, value: item.dial_code, data: item });
        }
      // }
    });
    setWhatsappIsdCodesOptions([
      ...newOptions,
    ]);
  };

  const updateSendEmailSelectOptions = (smsIsdCodesSelected, whatsappIsdCodesSelected) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('updateSendEmailSelectOptions smsIsdCodesSelected: ', smsIsdCodesSelected);
      console.log('updateSendEmailSelectOptions whatsappIsdCodesSelected: ', whatsappIsdCodesSelected);
    }
    let newOptions = [];
    if (smsIsdCodesSelected.find(el => el.value === 'all')) {
      newOptions.push(sendEmailOptions_all);
      setSendEmailOptions([
        ...newOptions,
      ]);
      setSendEmailValues({});
    } else {
      if (whatsappIsdCodesSelected.find(el => el.value === 'all-remaining')) {
        newOptions.push(sendEmailOptions_all);
        setSendEmailOptions([
          ...newOptions,
        ]);
        setSendEmailValues({});
        return;
      } else {
        setSendEmailOptions([
          ...sendEmailOptionsG,
        ]);
        // setSendEmailValues({});
      }
    }
    // newOptions = [];
    // if (whatsappIsdCodesSelected.find(el => el.value === 'all-remaining')) {
    //   newOptions.push(sendEmailOptions_all);
    //   setSendEmailOptions([
    //     ...newOptions,
    //   ]);
    //   setSendEmailValues({});
    //   return;
    // } else {
    //   setSendEmailOptions([
    //     ...sendEmailOptionsG,
    //   ]);
    //   // setSendEmailValues({});
    // }
    
  };


  const updateWhatsappSelectOptions = () => {
    const { approved_whatsapp_templates, added_whatsapp_templates } = props.settings.engage;
    if (props.type === 'preArrivalMessage' ||
      props.type === 'welcomeMessage' ||
      props.type === 'repeatWelcomeMessage' ||
      props.type === 'departureMessage' ||
      props.type === 'automatedNonBusinessHoursMessage' ||
      props.type === 'webchatWhatsappStarterMessage' ||
      props.type === 'webchatWhatsappNbhStarterMessage'
    ) {
      let tempList = [];
      if (approved_whatsapp_templates && approved_whatsapp_templates.length) {
        let filteredList = approved_whatsapp_templates.filter(el => el.type === MSGTYPE_TO_STR[props.type]);
        if (props.type === 'welcomeMessage' ||
          props.type === 'repeatWelcomeMessage'
        ) {
          filteredList = approved_whatsapp_templates.filter(el => (el.type === MSGTYPE_TO_STR['welcomeMessage'] ||
            el.type === MSGTYPE_TO_STR['repeatWelcomeMessage']
            ));
        }
        if (filteredList.length) {
          tempList = filteredList.map((el) => {
            return { label: el.title, value: el._id };
          });
          const foundMsg = filteredList.find(el => el._id === props.messageId);
          if (foundMsg) {
            setSelectedApprovedTemplate({
              label: foundMsg.title, value: foundMsg.msg,
            });
          }
        }
      }
      setApprovedTemplateOptions(tempList);
    }
  };

  const onHasMessageChange = (e) => {
    const { name, checked } = e.target;
    setHasMessage(checked);
    props.onHasMessageChange(checked);
  }

  const onSendAfterChange = (value) => {
    setSendAfterValue(value);
    props.onSendAfterChange(value);
  };
  const onSendBeforeChange = (value) => {
    setSendBeforeValue(value);
    if (value.value === 'Same Day') {
      props.onSendBeforeChange({ label: 'Same Day', value: '0 Day' });
      const checkInTime = moment(props.checkInTime, 'H:mm A');
      if (moment(sendAt).isSameOrBefore(checkInTime)) {
        setSendAtError(false);
        props.onSendAtErrorChange('');
      } else {
        setSendAtError(true);
        props.onSendAtErrorChange('error');
      }
    } else {
      props.onSendBeforeChange(value);
      setSendAtError(false);
      props.onSendAtErrorChange('');
    }
    // props.onSendBeforeChange(value);
  };
  const onSendAtTimeChange = (selectedTime) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendAtTimeChange: ', selectedTime);
      console.log('onSendAtTimeChange checkInTime: ', props.checkInTime);
    }
    const checkInTime = moment(props.checkInTime, 'H:mm A');
    if (process.env.NODE_ENV !== 'production') {
      console.log('checkInTime: ', checkInTime.format());
      console.log('sendBeforeValue: ', sendBeforeValue);
    }
    if (sendBeforeValue.value === 'Same Day') {
      if (moment(selectedTime, 'hh:mm A').isSameOrBefore(checkInTime)) {
        setSendAtError(false);
        props.onSendAtErrorChange('');
      } else {
        setSendAtError(true);
        props.onSendAtErrorChange('error');
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendAtTimeChange:: ', new Date(moment(selectedTime, 'hh:mm A')));
    }
    setSendAt(selectedTime ? new Date(moment(selectedTime, 'hh:mm A')) : null);
    props.onSendAtTimeChange(moment(selectedTime, 'hh:mm A'));
  };

  const isValidSendAtDate = (current) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('isValidSendAtDate: ', props.checkInTime);
    }
    let checkInTime = moment(props.checkInTime, 'H:mm A');
    if (process.env.NODE_ENV !== 'production') {
      console.log('checkInTime: ', checkInTime.format());
    }
    return current.isBefore(moment(checkInTime));
  };


  const onPersonalizeChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('messageEl: ', messageEl.current.selectionStart);
    }
    if (value && value.value !== 'personalize') {
      if (message) {
        // setMessage(message => `${message} ${values[0].label}`);
        setMessage(message => `${message.substring(0,messageEl.current.selectionStart)}${value.value}${message.substring(messageEl.current.selectionStart)}`);
        // props.onMessageChange(`${message} ${values[0].value}`);
        props.onMessageChange(`${message.substring(0,messageEl.current.selectionStart)}${value.value}${message.substring(messageEl.current.selectionStart)}`);
        // setTimeout(() => {
        //   setPersonalizeValues([{ label: 'Personalize', value: 'personalize' }]);
        // }, 100);
      } else {
        setMessage(message => `${value.value}`);
        props.onMessageChange(`${value.value}`);
      }
    }
  };
  const onMessageChange = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('messageEl: ', messageEl.current.selectionStart);
    }
    setMessage(e.target.value);
    props.onMessageChange(e.target.value);
  };

  const onOpenEmojiSelectorClick = (e) => {
    setEmojiSelectorOpen(emojiSelectorOpen => !emojiSelectorOpen);
  };
  const onEmojiClick = (emoji, e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onEmojiClick: ', emoji, ' # ', e);
    }
    if (emoji && emoji.native) {
      if (message) {
      // setMessage(message => `${message} ${emoji.native}`);
        setMessage(message => `${message.substring(0,messageEl.current.selectionStart)}${emoji.native}${message.substring(messageEl.current.selectionStart)}`);
        props.onMessageChange(`${message.substring(0,messageEl.current.selectionStart)}${emoji.native}${message.substring(messageEl.current.selectionStart)}`);
      } else {
        setMessage(message => `${emoji.native}`);
        props.onMessageChange(`${emoji.native}`);
      }
    }
  };

  const onAttachMediaClick = (e) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onAttachMediaClick');
    }
    props.onMediaAttachClick(props.type);
  };

  const onUploadedImageClick = (e) => {
    props.onShowUploadedImageClick();
  };
  const onRemoveAttachMediaClick = (e) => {
    props.onRemoveMediaClick();
  };

  const onSmsIsdCodesChange = (values) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSmsIsdCodesChange: ', values);
    }
    let tValues = [ ...values ];
    const allCountriesFound = values.find(el => el.value === 'all');
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSmsIsdCodesChange allCountriesFound: ', allCountriesFound);
    }
    if (values.length) {
      if (values[values.length-1].value !== 'all') {
        tValues = tValues.filter(el => el.value !== 'all');
        setSmsIsdCodesValues(tValues);
      } else {
        tValues = [{ label: 'All Countries', value: 'all'}];
        setSmsIsdCodesValues(tValues);
        setWhatsappIsdCodesValues([]);
      }
    } else {
      setSmsIsdCodesValues(tValues);
    }
    props.onSmsIsdCodesChange(tValues);
    // if (!allCountriesFound) {
      updateWhatsappIsdCodeSelectOptions(tValues);
      updateWhatsappSelectOptions();
    // }
    // if (allCountriesFound) {
    //   setSmsIsdCodesValues([{ label: 'All Countries', value: 'all'}]);
    // } else {
    //   setSmsIsdCodesValues(values);
    // }
    updateSendEmailSelectOptions(tValues, whatsappIsdCodesValues);
  };

  const onWhatsappIsdCodesChange = (values) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onWhatsappIsdCodesChange: ', values);
    }
    let tValues = [ ...values ];
    const allRemainingCountriesFound = values.find(el => el.value === 'all-remaining');
    if (process.env.NODE_ENV !== 'production') {
      console.log('onWhatsappIsdCodesChange allRemainingCountriesFound: ', allRemainingCountriesFound);
    }
    if (values.length) {
      if (values[values.length-1].value !== 'all-remaining') {
        tValues = tValues.filter(el => el.value !== 'all-remaining');
        setWhatsappIsdCodesValues(tValues);
      } else {
        tValues = [{ label: 'All remaining countries', value: 'all-remaining'}];
        setWhatsappIsdCodesValues(tValues);
      }
    } else {
      setWhatsappIsdCodesValues(tValues);
    }
    props.onWhatsappIsdCodesChange(tValues);
    if (values.length) {
      removeSmsIsdCodeSelectOption(values[values.length-1]);
    }
    updateSendEmailSelectOptions(smsIsdCodesValues, tValues);
    
    // if (!allCountriesFound) {
    //   updateWhatsappIsdCodeSelectOptions();
    //   updateWhatsappSelectOptions();
    // }
    // if (allCountriesFound) {
    //   setSmsIsdCodesValues([{ label: 'All Countries', value: 'all'}]);
    // } else {
    //   setSmsIsdCodesValues(values);
    // }
  };

  const onSendEmailChange = (value) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('onSendEmailChange: ', value);
    }
    setSendEmailValues(value);
    props.onSendEmailChange(value);
  };


  const onSelectedApprovedTemplatedChange = (value) => {
    setSelectedApprovedTemplate(value);
    if (props.onWhatsappMessageIdChange) props.onWhatsappMessageIdChange(value.value);
  };


  if (process.env.NODE_ENV !== 'production') {
    console.log('PreDefinedMessage sendAt: ', sendAt);
    console.log('PreDefinedMessage sendAtError: ', sendAtError);
    console.log('PreDefinedMessage sendEmailValues: ', sendEmailValues);
  }

  return (
    <div className="pDefMsg pDefSms">
      <div className="pDRw hdr">
        {props.heading}
      </div>
      <div className="pDRw hasTgl">
        <div className="lt">
          <div className="title">
            {props.title}
          </div>
          <div className="desc">
            {props.desc}
          </div>
        </div>
        <div className="rt">
          <div className="gToggleWrap">
            <div className="gToggle">
              <input type="checkbox" id={'hasMessage_'+props.type} checked={hasMessage} onChange={onHasMessageChange}/><label htmlFor={'hasMessage_'+props.type}>Toggle</label>
            </div>
          </div>
        </div>
      </div>
      {
        hasMessage &&
        <>
          <div className="pDRw msgRw">
            <label className="gLbl">Message</label>
            <div className="gIpt">
              <textarea
                className={'gIpt ' + (hasMessage && !message ? 'error' : '')}
                onChange={onMessageChange}
                placeholder="Message..."
                value={message}
                rows="4"
                ref={messageEl}
              />
            </div>
            <div className="emojiRw">
              <div className="emojiBox">
                <div className="emojiIcon" onClick={onOpenEmojiSelectorClick}>
                  {
                    emojiSelectorOpen
                    ? <i className="fa fa-times-circle" />
                    : <img src={emojiIcon} />
                  }

                </div>
              </div>
            </div>
            { emojiSelectorOpen &&
              <div className="emojiBox">
                <Picker data={emojiData}
                  onEmojiSelect={(emoji, e) => onEmojiClick(emoji, e)}
                />
              </div>
            }
            {
              segmentInfo && segmentInfo.segmentsCount > 1 &&
              <p className="segInfRw">
              <ToolTipWrapper placement="top" overlayClassName="sbTooltip" overlay={'Multiple credits will be used.'}>
                <span>
                  <i className="fas fa-info-circle"></i>
                </span>
              </ToolTipWrapper>
              Number of segments: {segmentInfo.segmentsCount}</p>
            }
            {
              props.hasMms &&
              <div className="atchRw">
                <div className="attachIcon">
                  <Dropzone onDrop={acceptedFiles => props.onDrop(acceptedFiles)} multiple={false}>
                  {({getRootProps, getInputProps}) => (
                    <div {...getRootProps({className: 'dndBox1'})}>
                      <input {...getInputProps()} />
                      <ToolTipWrapper placement="left" overlayClassName="sbTooltip" overlay={'Attach media'}>
                      <span onClick={onAttachMediaClick}>
                        <i className="fas fa-image"></i>
                      </span>
                      </ToolTipWrapper>
                    </div>
                    )}
                  </Dropzone>
                </div>
                {
                  props.mediaPath &&
                  <div className="uplThmb">
                    <ToolTipWrapper placement="right" overlayClassName="sbTooltip" overlay={props.mediaName}>
                      <img src={props.mediaPath} onClick={onUploadedImageClick} />
                    </ToolTipWrapper>
                    <ToolTipWrapper placement="right" overlayClassName="sbTooltip" overlay={"Remove media"}>
                      <div className="close" onClick={onRemoveAttachMediaClick}><i className="far fa-times-circle"></i></div>
                    </ToolTipWrapper>
                  </div>
                }
              </div>
            }
          </div>
          <div className="pDRw prslRw">
            <div className="tp">
              <label className="gLbl">Choose dynamic label to insert</label>
              <Select
                options={personalizeOptions}
                value={personalizeValue}
                onChange={onPersonalizeChange}
              />
            </div>
            <div className="hlpMsg">
              {props.dynamicLabel}
            </div>
          </div>
          {
            props.type === 'preArrivalMessage'
            ?
              <div className="pDRw sndAtRw">
                <div className="tp">
                  <div className="lt">
                    <label className="gLbl">Send Before</label>
                    <Select
                      options={sendBeforeDays}
                      value={sendBeforeValue}
                      onChange={onSendBeforeChange}
                      className={hasMessage && !sendBeforeValue.value ? 'hasError' : ''}
                    />
                  </div>
                  <div className="rt">
                    <label className="gLbl">Send At</label>
                    <CustomTimePicker
                      value={moment(sendAt).format('hh:mm A')}
                      onChange={onSendAtTimeChange}
                      every15Min={true}
                    />
                  </div>
                </div>
                <div className="hlpMsg">
                  {props.dynamicLabel}
                </div>
              </div>
            : null
          }
          {
            props.type === 'welcomeMessage' || props.type === 'repeatWelcomeMessage' || props.type === 'departureMessage'
            ?
              <div className="pDRw sndAftRw">
                <div className="tp">
                  <div className="lt">
                    <label className="gLbl">Send After</label>
                    <Select
                      options={sendAfterTimes}
                      value={sendAfterValue}
                      onChange={onSendAfterChange}
                      className={hasMessage && !sendAfterValue.value ? 'hasError' : ''}
                    />
                  </div>
                </div>
                <div className="hlpMsg">
                  {props.dynamicLabel}
                </div>
              </div>
            : null
          }
          {/* <div className="pDRw smsIsd">
            <div className="tp">
              <label className="gLbl">Choose recepient ISD codes for SMS message</label>
              <Select
                isMulti
                isClearable={true}
                options={smsIsdCodesOptions}
                value={smsIsdCodesValues}
                onChange={onSmsIsdCodesChange}
              />
            </div>
            {
              props.smsIsdHelp &&
              <div className="hlpMsg">
                {props.smsIsdHelp}
              </div>
            }
          </div> */}
          {
            smsIsdCodesValues.length && smsIsdCodesValues[0].value !== 'all'
              ?
              <>
                {/* <div className="pDRw smsIsd">
                  <div className="tp">
                    <label className="gLbl">Choose recepient ISD codes for Whatsapp message</label>
                    <Select
                      isMulti
                      isClearable={true}
                      options={whatsappIsdCodesOptions}
                      value={whatsappIsdCodesValues}
                      onChange={onWhatsappIsdCodesChange}
                    />
                  </div>
                  {
                    props.whatsappIsdHelp &&
                    <div className="hlpMsg">
                      {props.whatsappIsdHelp}
                      {
                        props.whatsappIsdHelpTt &&
                        <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={props.whatsappIsdHelpTt}>
                          <span>
                            <i className="fas fa-info-circle"></i>
                          </span>
                        </ToolTipWrapper>
                      }
                    </div>
                  }
                </div> */}
                {/* <div className="pDRw msgRw">
                  <label className="gLbl">{'Select approved whatsapp template for remaining ISD codes'}</label>
                  <div className="selctBx">
                    <Select
                      options={approvedTemplateOptions}
                      value={selectedApprovedTemplate}
                      onChange={onSelectedApprovedTemplatedChange}
                      className={hasMessage && !selectedApprovedTemplate.value ? 'hasError' : ''}
                    />
                  </div>
                  <div className="gIpt">
                    <textarea
                      className={'gIpt ' + (hasMessage && !whatsappMessage ? 'error' : '')}
                      // onChange={onMessageChange}
                      placeholder="Message..."
                      value={whatsappMessage}
                      rows="4"
                      // ref={messageEl}
                      readOnly
                    />
                  </div>
                </div> */}
              </>
              : null
          }
          {/* <>
            <div className="pDRw smsIsd">
              <div className="tp">
                <label className="gLbl">Choose condition for sending email</label>
                <Select
                  isClearable={true}
                  options={sendEmailOptions}
                  value={sendEmailValues}
                  onChange={onSendEmailChange}
                />
              </div>
              {
                props.sendEmailHelp &&
                <div className="hlpMsg">
                  {props.sendEmailHelp}
                  {
                    props.sendEmailHelpTt &&
                    <ToolTipWrapper placement="top" overlayClassName="sbTooltip maxWidth" overlay={props.sendEmailHelpTt}>
                      <span>
                        <i className="fas fa-info-circle"></i>
                      </span>
                    </ToolTipWrapper>
                  }
                </div>
              }
            </div>
          </> */}
        </>
      }
    </div>
  );
}

export default PreDefinedMessage;
